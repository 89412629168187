.settingschule{
    /* background-color: aqua; */
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.settingschuleitem{
    display: flex;
    flex-direction: column;
}

.timepickerr{
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    margin-bottom: 10px;
    
}
.timepickerr2{
    display: flex;
    align-items: center;
    width: 50%;
    /* background-color: aqua; */
    justify-content: center;
}

.timepickerr2 label{
    width: 100px;
}

.schduleform{
    display: block !important;
}

.meetuserdetails{
    /* background-color: aqua; */
    width: 80%;
    margin: 0;
    text-align: left;
    padding-left: 2%;
}

.orderlist{
    /* background-color: aqua; */
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80px;
    color: #4180B7;

}

.uparrow{
    cursor: pointer;
}

.downarrow{
    cursor: pointer;
}