.questionbox {
    /* background-color: aqua; */
    border: 4px solid #6E4E9F;
    border-radius: 20px;
    justify-content: baseline;
    /* margin-top: 50px; */
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
}

.question {
    /* background-color: blue; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    height: fit-content;
    position: relative;
}

.question::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #6E4E9F;
}

.dltbtndiv{
    padding: 10px 0;
    display: flex;
    width: 100%;
    justify-content: right;
}

.dltbtn{
    background-color: rgba(255, 0, 0, 0.178);
    border: 1px solid red;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    color: red;
}

.questionbox2 {
    border: 1px solid #6E4E9F;
    justify-content: baseline;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: baseline;
    position: relative;
}
.questionbox2-chat {
    border: none !important;
    border: 1px solid #6E4E9F;
    border-radius: 10px;
    justify-content: baseline;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: baseline;
    background: #dcd2fc;
    position: relative;
}
.newquestionbox2{
    background: #6E4E9F;
    border-radius: 10px;
    color: #dcd2fc;
}
.marginbottom{
    margin-bottom: 10px !important;
}
.button-style {
    background: #dcd2fc;
    border-radius: 14px;
    color: #6e4f9f;
    padding: 9px 30px;
    font-size: 15px;
}
.button-style-2 {
    color: #dcd2fc;
    border: 2px solid #dcd2fc;
    border-radius: 14px;
    background: #6e4f9f;
    padding: 7px 27px;
    font-weight: bold;
    font-size: 15px;
  }
  
  
.questionbox211{
    position: absolute;
    top: 20px;
    right: 20px;
}


.sharp-corner-box {
    margin-bottom: 20px;
    width: 75%;
    padding: 20px;
    background-color: #d2caca;
    /* border: 1px solid #ddd; */
    position: relative;
    border-radius: 0;
}
.sharp-corner-box::before {
    content: '';
    position: absolute;
    top: 4px;
    left: -9px;
    width: 20px;
    height: 20px;
    background-color: #d2caca;
    /* border: 1px solid #ddd; */
    /* border-radius: 0 0 0% 240%; */
    transform: rotate(-45deg);
}
.reciever{
    background-color: #6E4E9F;
    color: #dcd2fc;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
.sender{
    background-color: white;
    color: #6E4E9F;
    /* border-top-right-radius: 10px; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .sharp-corner-box::before {
    background-color: #6e4f9f !important;
  }
  
  .sharp-corner-box2 {
    width: 75%;
    margin-bottom: 20px;
    padding: 20px;
    /* background-color: #d2caca; */
    /* border: 1px solid #ddd; */
    position: relative;
    /* border-radius: 0; */
}
.sharp-corner-box2::after {
  background-color: white !important;
}
.sharp-corner-box2::after {
    content: '';
    position: absolute;
    top: 4px;
    right: -9px;
    width: 20px;
    height: 20px;
    background-color: #d2caca;
    /* border: 1px solid #ddd; */
    /* border-radius: 0 0 0% 240%; */
    transform: rotate(-45deg);
}