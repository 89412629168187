.leadinforhead {
    display: flex;
    gap: 10px;
    /* background-color: aqua; */
    /* background-color: ; */
    /* width: 49%; */
}

.sourcecheckbox {
    /* background-color: aqua; */
    position: relative;
    padding: 5px 0;
    display: flex;
    align-items: baseline;
}

.sourcecheckbox::after {
    position: absolute;
    width: 100%;
    content: "";
    height: 1px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.123);
    bottom: 0;
}