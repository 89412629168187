.Practitionermainbody {
    width: 72%;
    /* background-color: rgb(250, 161, 60); */
    height: fit-content;
    min-height: 500px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    padding: 10px 2%;
}

.header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    color: #6E4E9F;
    /* background-color: aqua; */
}



.header form {
    display: flex;
    align-items: center;
    /* height: 50px; */
    justify-content: center;
    /* background-color: yellow; */
    /* justify-content: space-between; */
}

.header form AutoComplete {

    width: 80%;
    height: 10px;
    padding: 10px 20px;
    border: 1px solid #dbdbdb;
    border-radius: 5px 0 0 5px;
    color: #565d68;
    transition: all .2s linear;
    outline: none;
}

.header form AutoComplete:focus {
    outline: none;
    border-color: #4180b7;
    box-shadow: 0 0 0 0.2rem #4180b744;
}

.header form button {

    background-color: #4180b7;
    color: #fff;
    border: none;
    border-radius: 0 5px 5px 0;
    height: 31px;
}

.header form button:hover {

    cursor: pointer;
}

.addbutton {
    /* background-color: #4180b7; */
    display: flex;
    margin-top: 50px;
    margin-bottom: 10px;
    justify-content: flex-end;

}

.addbutton button:hover {
    cursor: pointer;
}

.addbutton button {

    background-color: #4180b7;
    color: #fff;
    border: none;
    border-radius: 5px;
    height: 31px;
    display: flex;
    padding: 10px 20px;
    align-items: center;

}

.tablep {
    /* background-color: yellow; */
    width: 100%;
    border: 1px solid #4180b7;
    text-align: center;
    /* border-collapse: collapse; */
    position: relative;
    border-radius: 10px;
    /* opacity: 1; */
    /* border-radius: 10px; */
    /* background-color: #6E4E9F; */
}

.tablep td {
    /* padding: 10px; */
    border: 1px solid #4180b71a;
    text-align: left;
    color: #6E4E9F;



}



.tablep th {
    padding: 10px;
    text-align: left;
    /* background-color: #6F4FA0; */
    /* color: #fff; */
    /* border-radius: 0px !important; */
    /* border: 1px solid #4180b7; */




}


.tablephead th:first-child {
    border-top-left-radius: 10px;
}

.tablephead th:last-child {
    border-top-right-radius: 10px;
}

.tablephead {
    background-color: #6E4E9F;
    color: white;
    position: sticky;
    top: 0;
}


.addPractitioner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: baseline;
    align-items: flex-start;

}

.popupbg2 {
    background-color: rgb(63 60 60 / 23%);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 25;
}










.whiteboard {
    /* background-color: #6E4E9F; */
}



.whiteboard h2 {
    font-weight: bold;
    font-size: 1rem;
}

.whiteboard p {

    font-size: 0.8rem;
}

.whiteboard ul,
.whiteboard li {
    list-style: none;
}



.whiteboard ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.whiteboard ul li a {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: fit-content;
    min-height: 12em;
    min-width: 10em;
    max-width: 50%;
    padding: 1em;
    box-shadow: -5px 5px 7px rgba(33, 33, 33, .7);
    transform: rotate(-4deg);
    transition: transform .15s linear;
    position: relative;
}

.notesby {
    position: absolute;
    bottom: 0;
    opacity: 0.5;
    font-size: 0.7rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    /* background-color: #565d68; */
}

.dateeeeeee {
    position: absolute;
    bottom: 10px;
    opacity: 0.5;
    font-size: 0.7rem;
}

.whiteboard ul li:nth-child(even) a {
    transform: rotate(4deg);
    position: relative;
    top: 5px;
    background: #cfc;
    box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
}


.whiteboard ul li:nth-child(3n) a {
    transform: rotate(-3deg);
    position: relative;
    top: -5px;
    background: #ccf;

}

.whiteboard ul li:nth-child(5n) a {
    transform: rotate(5deg);
    position: relative;
    top: -10px;
}

.whiteboard ul li a:hover,
.whiteboard ul li a:focus {
    box-shadow: 10px 10px 7px rgba(0, 0, 0, .7);
    transform: scale(1.25);
    position: relative;
    z-index: 5;
}

.whiteboard ul li {
    margin: 1em;
    /* background-color: #4180b7;
    width: fit-content; */
}
.editfield{
    width: 100% !important;
    height: 7em !important;
    border: none !important;
    text-align: center !important;
    background: none !important;
    padding: 0 !important;
    box-sizing: none !important;
}

.editfield:focus{
    box-sizing: 0 0 0 0 !important;
}
.editfield:focus-visible{
    /* width: 100%;
    height: 7em; */
    border: none !important;
    outline: none !important;
}

.editfield2{
    width: 100% !important;
    height: 15px !important;
    text-align: center !important;
    border: none !important;
    background:none !important;
    font-weight: 900 !important;
    padding: 0 !important;
    border-radius: 0 0 0 0 !important;
    box-sizing: none !important;
}
.editfield2:focus-visible{
    /* width: 100%;
    height: 7em; */
    border: none !important;
    outline: none !important;
}



















@media screen and (max-width:700px) {


    .Practitionermainbody {
        width: 96%;
        /* overflow-x: scroll; */
        font-size: 75%;
        /* background-color: rgb(250, 161, 60); */
        height: 500px;
        overflow-x: scroll;
        font-size: smaller;
    }

    .header {

        flex-direction: column;
    }

    .bownpopupbutton {
        /* background-color: #4180b7; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        /* flex-direction: column; */
    }

}