.profilemainbody {
    width: 72%;
    height: fit-content;
    background-color: #fff;
    border-radius: 10px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;

}

.uperprofile {

    width: 90%;
    height: fit-content;
    /* background-color: aqua; */
    display: flex;
}


.profilepicture {
    width: 50%;
    height: 100%;
    /* background-color: yellow; */
}

.profileabot {
    width: 50%;
    height: fit-content;
    text-align: left;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 10px;

}

.profileabot div{
    /* background-color: #4180b7; */
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: first baseline;
}

.profileabot div h3{
   color: #6E4E9F;
}

.profileabot div h2{
    color: #6E4E9F;
 }

.profileabot div p{
    color: #4180B7;
position: relative; 
}

 .profileabot div p::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #6f4e9f66;
    left: 0;
    bottom: 0px;
 }


.profileinfo {

    /* background-color: aqua; */
    width: 90%;
    margin-top: 50px;
    height: 100px;
    box-shadow: 0 0 10px 1px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 50px;
}


.profileinfo2 {

    /* background-color: aqua; */
    width: 90%;
    margin-top: 50px;
    height: fit-content;
    box-shadow: 0 0 10px 1px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    padding: 5px 5px;
}

.infoboxinside {
    /* background-color: aqua; */
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profileeditingform {
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    gap: 20px;
}



.buttonnnnn {
    /* background-color: blue; */
    width: 86%;
    display: flex;
    justify-content: space-between;
}

.editprofileinputs {
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.editprofileinputs label{
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addadmin{
    display: flex;
    /* background-color: aqua; */
    width: 90%;
    justify-content: space-between;
    padding: 5%;
}

.addadmin button{
    background-color: #4180b7;
    color: #fff;
    border: none;
    border-radius: 5px;
    height: 31px;
    display: flex;
    padding: 10px 20px;
    align-items: center;
}

.autocoma{
    width: 100%;
    /* background-color: #4180b7; */
}

@media screen and (max-width:1060px) {
    .autocoma{
        width: 60%;
        /* background-color: #4180b7; */
    }

    .profileinfo2 {
        /* background-color: aqua; */
        width: 90%;
        margin-top: 50px;
        height: -moz-fit-content;
        height: fit-content;
        box-shadow: 0 0 10px 1px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        align-items: center;
        padding: 5px 5px;
        overflow: auto;
    }
    .profilemainbody {
        width: 96%;
        height: fit-content;
        background-color: #E8EAFF;
        border-radius: 10px;
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: smaller;
    
    }
    .uperprofile {
        width: 100%;
        height: fit-content;
        /* background-color: aqua; */
        display: flex;
        flex-direction: column;
    }

    .profilepicture {
        width: 100%;
        height: 100%;
        /* background-color: yellow; */
    }

    .profileabot {
        width: 100%;
        height: 100%;
        text-align: left;
        /* background-color: rgb(0, 38, 255); */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    }




















    .gradient-custom {
        /* fallback for old browsers */
        background: #f6d365;
      
        /* Chrome 10-25, Safari 5.1-6 */
        background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));
      
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1))
      }