.firstdiv {
    width: 210mm;
    height: 297mm;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.firstdivhead {

    width: 210mm;
    height: 27mm;
    display: flex;
    /* background-color: #8469B5; */

}



.firstdivhead img {

    width: 26mm;

    margin-left: 5mm;

}

.firstdivhead img+img {

    width: 174mm;


}

.firstdivcenter {
    font-size: 12mm;
    color: #8469B5;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #8469B5; */
    width: 100%;
    height: 300px;
}

.firstdivend img {

    width: 210mm;


}

.secenddiv {

    width: 210mm;
    height: 297mm;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: #8469B5; */
    font-size: 0.7em;
    font-weight: 500;


}

.secenddivhead {

    width: 210mm;
    display: flex;

}

.secenddivhead img {

    width: 40mm;

    margin-left: 5mm;
}

.secenddivhead img+img {

    width: 160mm;


}

.secenddivend img {
    width: 210mm;

}


.secenddivcenter {
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.report {
    background-color: #C7C8FE;
    background: linear-gradient(90deg, #98a4f3, #dfe3fc, #d3bff3);
    width: 200mm;
    border-radius: 20px;
    text-align: center;
    height: 10mm;
    line-height: 10mm;
}

.reporttext {
    /* background-color: black; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 200mm;
    height: 15mm;
    font-size: smaller;
    margin-bottom: 5mm;

}

.reportleft {
    width: 170mm;
    /* background-color: #8469B5; */
    display: flex;
    flex-direction: column;

}

.reportleft span {
    border-top: 1px solid rgb(187, 178, 178);
    display: flex;
    align-items: center;
    width: 170mm;
    height: 5mm;
    justify-content: space-around;
}

.reportleft span h4,
.reportleft span h5 {

    width: 50mm;
    /* background-color: #8469B5; */


}

.reportright {
    font-size: small;
    /* line-height: 1mm; */

}

.maindivvvv {

    width: 210mm;
    display: flex;
    gap: 10px;
    /* align-items: center; */
    justify-content: center;
    margin-bottom: 2mm;

}

.maindivvvvleft {
    width: 200mm;
    /* background-color: #6E4E9F; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
}

.testreport_heading {
    background-color: #6E4E9F;
    padding: 10px 200px;
    border-radius: 100px;
    color: #fff;
}

.maindivvvvleft .result {
    background-color: #C7C8FE;
    background: linear-gradient(90deg, #98a4f3, #dfe3fc, #d3bff3);

    width: 200mm;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7mm;
    font-size: 1em;
    border-radius: 20px;
}

.maindivvvvright .inter {
    background-color: #C7C8FE;
    width: 45mm;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7mm;
    font-size: small;
    border-radius: 20px;
}

.box {
    /* padding: 1mm; */
    margin-top: 1mm;
    /* background-color: #E7E8FF; */
    width: 185mm;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* align-items: ; */
    /* justify-content: space-around; */
}

.boxhead {

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40mm;
    /* background: linear-gradient(90deg, #98a4f3, #dfe3fc, #d3bff3); */

    /* padding: 0 20mm; */
    width: 190mm;
    font-size: small;
    height: 6mm;
    border-radius: 20px;
    margin-left: 2mm;
    margin-top: 1mm;
    background-color: #6e4e9f;
    color: #fff;
}

.box table {

    width: 186mm;


}

.headinggg {
    vertical-align: top;
    border-bottom: none;
    font-weight: 700;
}

.box table tbody tr {
    display: flex;
    flex-direction: column;
    background-color: #E7E8FF;
    width: 45.3mm;
    height: 15mm;
    border-radius: 4mm;
    font-weight: 900;

}

.box tbody {
    display: flex;
    gap: 2mm;
    margin-bottom: 2mm;
    flex-wrap: nowrap;
}

.box td:nth-child(1) {
    background-color: #E7E8FF;
    background: linear-gradient(90deg, #98a4f3, #dfe3fc, #d3bff3);
    border-radius: 10mm;
    font-weight: 900;
    color: black;
    /* height: 100mm; */

}


.box td {
    /* padding: 8px;  */
    /* background: linear-gradient(90deg, #98a4f3,#dfe3fc, #d3bff3); */
    /* background-color: #6e4e9f; */
    height: 10mm;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* border-bottom: 1px solid rgba(128, 128, 128, 0.397); */

}

/* tr:last-child {

    border-bottom: none;
} */

.box-2 {

    background-color: #E7E8FF;
    /* padding: 1mm; */
    text-align: center;
    border-radius: 10px;
    width: 40mm;
    /* background-color: #8469B5; */
    height: 110mm;
    display: flex;
    flex-direction: column;
    padding: 3mm;
    align-items: center;
    justify-content: center;
    margin-top: 1mm;

}

.informatinhead {

    display: flex;
    align-items: center;
    justify-content: center;


    width: 210mm;
    font-size: small;
    height: 8mm;
    border-radius: 20px;
    margin-left: 2mm;
    margin-top: 1mm;
    margin-bottom: 2mm;
    background-color: #C7C8FE;

}

.informationtext {
    background-color: #E7E8FF;
    padding: 2mm;
    border-radius: 10px;
    width: 196mm;
    margin-top: 1mm;
}

.lastdiv {


    width: 210mm;
    height: 296mm;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

.lastdivcenter {
    margin: 0 10mm;
}

.texthead {

    display: flex;
    align-items: center;
    justify-content: center;


    width: 190mm;
    font-size: small;
    height: 8mm;
    border-radius: 20px;
    margin-left: 2mm;
    margin-top: 1mm;
    margin-bottom: 2mm;
    background-color: #C7C8FE;

    background: linear-gradient(90deg, #98a4f3, #dfe3fc, #d3bff3);



}

.lastdivtext {

    height: fit-content;
    background-color: #E7E8FF;
    padding: 2mm;
    border-radius: 20px;
    text-align: left;
    font-size: smaller;
    min-height: 130mm;
}

.colorchange {
    background-color: red !important;
    color: #fff;
    /* background-color: #8469B5; */
    /* text-align: left; */
}


.mainnnnnnnnn {
    /* background-color: #6e4e9f; */
    display: flex;
    justify-content: center;
}


@media screen and (max-width:1060px) {
    .mainnnnnnnnn {
        /* background-color: #6e4e9f; */
        display: block;
        justify-content: center;
    }

    .downloadbuttonviewreport {
        left: 105mm;
    }
}