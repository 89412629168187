.filename{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #4180B7;
}




.custom-file-upload{
    background-color: yellowgreen;
width: 100%;
height: 300px;

}
.inputbuttontouploadfile{
    display: none;
}

.clickablediv{
    background-color: #c9c0c06b;
    width: 100%;
    height: 250px;
    border-radius: 10px;
    border: 5px dashed #4180B7;
    color: #4180B7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.clickablediv:hover{
    cursor: pointer;
    background-color: #6d6a6a6b;
}

.tablereport {
    /* background-color: yellow; */
    font-size: 70%;
    width: 100%;
    border: 1px solid #4180b7;
    text-align: left;
}

.downloadbutton{
    background-color: #4180b7;
    color: #fff;
    border: none;
    border-radius: 5px;
    height: 31px;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    margin-top: 10px;
    text-decoration: underline #4180b7;
    gap: 5px;
}

.downloadbutton:hover{
    cursor: pointer;
}

.downloadbutton a {
    text-decoration: none;
    background-color: aqua;
}

.showreportlogs{
    /* background-color: gray; */
    text-align: left;
    max-height: 500px;
    font-family: "monospace";
    overflow: auto;
    font-size: 75%;
}