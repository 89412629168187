.rebinforp {
  /* background-color: aqua; */
  position: absolute;
  right: -20px;
  text-align: right;
}

.rebinforp2 {
  background-color: #fff;

  position: absolute;
  right: 0;
  top: 40px;
  padding: 2px 10px;
  border-radius: 10px 0 0 10px;
  color: #6E4E9F;

}

.newmessagesline {
  text-align: center;
  display: flex;
  justify-content: center;
  /* background-color: #007bff; */
  color: #6E4E9F;
  position: relative;
}

.newmessagesline h4 {
  background-color: #fff;
  color: #6E4E9F;
  position: relative;
  width: fit-content;
  padding: 0 20px;
  z-index: 10;
}

.newmessagesline::before {
  position: absolute;
  content: "";
  width: 40%;
  height: 0px;
  border-top: 5px dashed #6E4E9F;
  left: 0;
  top: 40%;
}

.newmessagesline::after {
  position: absolute;
  content: "";
  width: 40%;
  height: 0px;
  border-top: 5px dashed #6E4E9F;
  /* background-color: #6E4E9F; */
  right: 0;
  top: 40%;
}

.filesss {
  /* background-color: #007bff; */

  flex-direction: row !important;
  max-width: 100%;
  display: flex !important;
  flex-wrap: wrap !important;

}

.fileview {
  cursor: pointer;
  background-color: #BCC2FE;
  margin-right: 20px;
  display: flex;
  gap: 5px;
  border-radius: 10px;
  padding: 5px 10px;
  text-decoration: none;
  color: #fff;
  align-items: center;
}

.filesss div {
  /* background-color: #6E4E9F; */
  max-width: 172px;
  flex-grow: 1 !important;
  flex-basis: 200 !important;
}

.filesss div img {
  object-fit: cover;
}

.ribbon {
  --f: 15px;
  /* control the folded part */

  position: absolute;
  top: 0;
  color: #fff;
  padding: .1em 1.8em;
  background: var(--c, #ff0202);
  border-bottom: var(--f) solid #0007;
  clip-path: polygon(100% calc(100% - var(--f)), 100% 100%, calc(100% - var(--f)) calc(100% - var(--f)), var(--f) calc(100% - var(--f)), 0 100%, 0 calc(100% - var(--f)), 999px calc(100% - var(--f) - 999px), calc(100% - 999px) calc(100% - var(--f) - 999px))
}

.right {
  right: 0;
  transform: translate(calc((1 - cos(45deg))*100%), -100%) rotate(45deg);
  transform-origin: 0% 100%;
  font-size: 0.7em;
  z-index: 20;
}

.cloedtickets {
  background-color: #fff !important;
  color: #6E4E9F;
  box-shadow: 0 0 1px 1px #6E4E9F;

}


.Repliedtickets {
  background-color: #e1d1f8 !important;
  color: #6E4E9F;
  box-shadow: 0 0 1px 1px #6E4E9F;

}

.lastmessage {
  /* background-color: #007bff; */
  width: 60%;
  text-align: left;
}

.messagebubbble {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  /* width: 100%; */
}

.messagebubbble2 {

  border-radius: 10px;
  /* background-color: #21a503; */
  /* padding: 20px; */
  display: flex;

}

.messagebubbble3 {

  border-radius: 10px;
  /* background-color: #677500; */
  /* padding: 20px; */
  display: flex;
  justify-content: end;

}

.ticketcantainer {
  /* background-color: #6E4E9F; */
  color: #fff;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ticketcantainerinside_header {
  /* background-color: #cfcbf5; */
  display: flex;
  justify-content: space-between;
}

.ticketcantainerinside_header div {
  /* background-color: #cfcbf5; */
  display: flex;
  /* background-color: #6E4E9F; */
  align-items: center;
  gap: 10px;
  /* justify-content: space-between; */
}

.ticketcantainerinside_header div p {
  margin: 0;
}

.ticketcantainerinside {
  background-color: #6E4E9F;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  flex: 1;
  cursor: pointer;
}

.ticketcantainerinside:hover {
  background-color: #8c6fb7;
}


.input-container {
  width: 100%;
  background-color: #D2CDFF;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.input-box {
  width: 100%;

  /* height: fit-content; */
  /* height: auto; */
  max-height: 500px;
  height: 30px;
  /* padding: 10px; */
  border: 1px solid #D2CDFF;
  /* border-radius: 8px 8px 0 0; */
  font-size: 14px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  /* background-color: #6E4E9F; */
}

.input-box::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.input-box::-webkit-scrollbar-thumb {
  background: transparent;
  /* Optional: makes the thumb transparent */
}

/* .input-box::placeholder {
    color: #131212;
  } */

.icons {
  display: flex;
  justify-content: space-around;
  padding: 0px 10px;
  background-color: transparent;
  border-top: 1px solid #cfcbf5;
  align-items: center;
  /* background-color: #007bff; */
  gap: 5px;
}

.icon {
  font-size: 30px;
  color: #6E4E9F;
  cursor: pointer;
}

.icon:hover {
  color: #007bff;
}








.message-container {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
  width: 100%;
  margin-bottom: 50px;
  text-align: left;

}


.sideee {
  justify-content: flex-end !important;
}

.message-bubble {
  background-color: #E4C9F9;
  padding: 15px;
  border-radius: 20px;
  max-width: 300px;
  position: relative;
}

.rec {
  background-color: #6E4E9F;
  position: relative;
  border-radius: 0 20px 20px 20px;
  margin-left: 20px;
  min-width: 50%;
  max-width: 80%;
  width: fit-content;
  color: #fff;
}

.rec::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-top: 20px solid #6E4E9F;
  left: -30px;
  top: 0;
}


.send {
  background-color: #BCC2FE;
  position: relative;
  border-radius: 20px 0px 20px 20px;
  margin-right: 20px;
  min-width: 50%;
  max-width: 80%;
  width: fit-content;
  color: #fff;
}

.send::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-right: 60px solid transparent;
  border-top: 20px solid #BCC2FE;
  right: -30px;
  top: 0;
}


.message-text {
  margin: 0;
  color: #333;
  min-width: 150px;
  font-size: 14px;
}

.date-text {
  position: absolute;
  bottom: -18px;
  right: 10px;
  font-size: 12px;
  color: #B0B0B0;
}

.date-text2 {
  position: absolute;
  top: -18px;
  right: 10px;
  font-size: 12px;
  color: #B0B0B0;
}

.Messagearea {
  /* background-color: #007bff; */
  border-radius: 10px;
  border: 1px solid gray;
  padding: 30px;
}

.Messageareashow {
  padding: 20px;
  /* background-color: #007bff; */
  height: fit-content;
  padding-bottom: 80px;
  max-height: 500px;
  overflow-y: auto;
}

.taskheader {
  /* background-color: #007bff; */
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.taskheader_menu {
  background-color: #6E4E9F;
  color: #fff;
  display: flex;
  padding: 10px 20px;

  font-weight: 900;
  border-radius: 20px;
  gap: 10px;
  align-items: center;
  font-size: 1.3em;
  cursor: pointer;
}

.taskheader_number {
  background-color: #BCC2FE;
  border-radius: 50%;
  color: #6E4E9F;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  font-size: 0.8em;
}

.ticketheaderrr {
  /* background-color: #007bff; */
  display: flex;
  width: 100%;
}

.ticketheaderrricon {
  /* background-color: #6E4E9F; */
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 5%;
  width: 10%;
  color: #6E4E9F;
  padding: 0 20px;
  justify-content: center;

}


.taskheader_menu2 {
  background-color: #BCC2FE;
  display: flex;
  padding: 10px 20px;
  color: #6E4E9F;
  font-weight: 900;
  border-radius: 20px;
  gap: 10px;
  align-items: center;
  font-size: 1.3em;
  cursor: pointer;
}

.taskheader_number2 {
  background-color: #6E4E9F;
  border-radius: 50%;
  color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  font-size: 0.8em;
}