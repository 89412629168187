.navbar {
    width: 90%;
    height: 100px;
    /* background-color: #4180b7    ; */
    /* background-color: #6e4e9f    ; */
    background-color: #fff;
    display: flex;
    padding: 0 5%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 5px 0.5px #6e4e9f;
    /* border-radius: 0px 0px 50px 50px; */
    position: fixed;
    z-index: 20;
}

.mainsidemanu {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    width: 22%;
    height: fit-content;
    max-height: 80vh;
    gap: 30px;
    padding: 30px 0px 30px 0px;
    display: flex;
    flex-direction: column;
    text-align: left;
    position: sticky;
    top: 120px;
    z-index: 10;
    overflow-y: auto;
}




.mainsidemanu h3 {
    margin-left: 30px;
    color: #6e4e9f;
    display: flex;
    gap: 10px;
    /* justify-content: center; */
    align-items: center;
    text-align: left;
    /* background-color: #6e4e9f; */
    width: fit-content;
    font-weight: 600;
    font-size: 1em;
}

.mainsidemanu h3:hover {
    cursor: pointer;
    color: #4180b7;
    text-decoration: underline #4180b7;
}

.mainsidemanu ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: -15px;
    margin-left: 26px;
    font-size: 1em;
    margin-bottom: -10px;

}

.mainsidemanu li {

    margin: 0;
    /* margin-left: 80px; */
    color: #6e4e9f;
    display: flex;
    gap: 10px;
    /* justify-content: center; */
    align-items: center;
    text-align: left;
    /* background-color: #6e4e9f; */
    width: fit-content;
}

.mainsidemanu li:hover {
    cursor: pointer;
    color: #4180b7;
    text-decoration: underline #4180b7;
}




.link {
    position: relative;
}

.link:hover {
    color: #6e4e9f !important;
}

.link::after {
    content: "";
    width: 00%;
    height: 2px;
    background-color: #6e4e9f;
    position: absolute;
    bottom: 3px;
    left: 0;
    transition: 0.3s ease;
}

.link:hover::after {
    width: 100%;
}








.mobmanu ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: -29px;
    margin-left: 26px;

}

.mobmanu li {

    margin: 0;
    /* margin-left: 80px; */
    color: #6e4e9f;
    display: flex;
    gap: 10px;
    /* justify-content: center; */
    align-items: center;
    text-align: left;
    /* background-color: #6e4e9f; */
    width: fit-content;
}

.mobmanu li:hover {
    cursor: pointer;
    color: #4180b7;
    text-decoration: underline #4180b7;
}

.navmanu {
    display: block;
    letter-spacing: 1.3px;
    
}
.navmanu h5{
    display: block;
    
}

.navmanumob {
    display: none;
}



.mobmanubg {
    display: none;
}

.mobmanu {
    display: none;
}







.notifications {
    width: 400px;
    height: 600px;
    background: rgba(65, 128, 183, 0.6);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10.5px);
    -webkit-backdrop-filter: blur(10.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    color: #fff;
    position: fixed;
    top: 80px;
    right: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 50px 0;
    overflow: auto;
    z-index: 100;
}


.subnoti {
    background-color: #fff;
    width: 80%;
    z-index: 200;
    padding: 20px 5%;
    color: #000;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: baseline;
    text-align: left;
    background: rgba(194, 194, 194, 0.85);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(16.5px);
    -webkit-backdrop-filter: blur(16.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.bglight {
    background: rgb(255, 255, 255);
}


.ant-space-item {
    justify-content: center;
    align-items: center;
    display: flex;
}
.notificationbg{
    background-color: #00000033;
    position: fixed;
    z-index: 90;
    width: 100%;
    height: 100vh;

}

@media screen and (max-width:1060px) {

    .notifications {
        width: 100%;
        height: 90vh;
        right: 0;
        top: 100px;
    }

    .mainsidemanu {
        display: none;
    }

    .navbar {
        width: 96%;
        padding: 0 2%;

    }

    .navmanu {
        display: none !important;
    }

    .navmanumob {
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
    }




    .mobmanubg {
        display: block;
        background-color: rgba(0, 0, 0, 0.363);
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 10;
        top: 100px;
    }

    .mobmanu {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        height: fit-content;
        position: fixed;
        z-index: 100;
        top: 100px;
        gap: 50px;
        max-height: 61.6835vh;
        padding: 60px 20px;
        display: flex;
        flex-direction: column;
        text-align: left;
        overflow-y: scroll;
        padding-bottom: 164px;
    }

    .mobmanu h3 {
        /* margin-left: 50px; */
        color: #6e4e9f;
        display: flex;
        gap: 10px;
        /* justify-content: center; */
        align-items: center;
        text-align: left;
        /* background-color: #6e4e9f; */
        width: fit-content;
    }

    .mobmanu h3:hover {
        cursor: pointer;
        color: #4180b7;
        text-decoration: underline #4180b7;
    }


}