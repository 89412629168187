.printt {
    width: 210mm  !important;
    height: fit-content !important;
    /* background-color: yellow !important; */

}

.prrintbox {
    /* background-color: aqua !important; */
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 1px !important;
    align-items: center !important;
    /* justify-content: center !important; */
    padding: 0 1px !important;
   
}

#print{
    font-family: 'poppins';
}

#printt{
    font-family: 'poppins';
}

.prrintbox2 {
    /* background-color: aqua !important; */
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap !important;
    gap: 1px !important;
    align-items: center !important;
    /* justify-content: center !important; */
    padding: 0 1px !important;
   
}
.insideprint{
    display: flex !important;
    /* background-color: aquamarine !important; */
    width: 70% !important;
    justify-content: space-between !important;
}
.labelprint {
    width: 90mm !important;
    height: 205px !important;

    display: flex !important;
    border: 1mm solid rgb(255, 255, 255) !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: left !important;
    font-size: 0.8em !important;
    position: relative !important;
    padding: 9mm 6mm  !important;
    border-radius: 20px !important;
    /* background-color: violet; */
}

.printimg{
    width: 100px !important;
}