.shippinfform {
    /* background-color: aqua; */
    flex-direction: column;
    gap: 20px;
}

.shippinfform input,.shippinfform textarea, .shippinfform select{
    background-color: #D2CDFF;
    width: 96% !important;
    height: fit-content;
}
.shippinfform select{
    background-color: #D2CDFF;
    width: 100% !important;
    height: fit-content;
}

.autocompleee input{
    background-color: #D2CDFF !important;
    width: 100% !important;
    height: fit-content;
    padding: 0;
}

.ant-select-selector{
    padding: 0;
    background-color: #D2CDFF !important;
}
.autocompleee {
    background-color: #D2CDFF !important;
    width: 100% !important;
    height: fit-content;
    padding: 0;
}


textarea {
    background-color: #D2CDFF;
    /* width:  ; */
    height: fit-content;
}



.lableee {
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #7050A0;
    font-weight: bold;
}

@media screen and (max-width:1060px) {
    .shippinfform {
        /* background-color: aqua; */
        flex-direction: column;
        gap: 20px;
        width: 90% !important;
    } 
}