#ali{
    font-family: 'poppins';
    font-size: 0.9em;
    /* line-height: 15px; */
}

.pdfpage {
    width: 210mm;
    height: 297mm;
    font-size: small;
    /* background-color: #560dcb; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pdfpage4 {
    width: 210;
    height: 296mm;
    min-height: 294mm;
    font-size: small;
    /* background-color: #9f95af; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.screen-display {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    font-size: small;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.screen-display2 {
    width: 100%;
    height: fit-content;
    font-size: small;
    /* background-color: aqua; */
}

.pdfpage222 {
    width: 100%;
    height: fit-content;
    font-size: small;
    /* background-color: aqua; */
}

.screen-display {
    width: 100%;
    /* height: 10vh; */
}


.logoimg {
    width: 465px;
}








.uperimg {
    width: 100%;
    height: 200px;

}

.uperimgp {
    /* width: 150%; */
    height: 180px;
    transform: translateX(-40px);
    /* transform: translateX(-40px); */
}

.pageecontant {
    width: 100%;
    height: fit-content;
    /* position: relative;
    top: 0px; */
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pageecontant2 {
    width: 100%;
    height: fit-content;
    /* position: relative;
    top: 0px; */
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.logoimg2 {
    width: 20%;
}

.info {
    width: 98%;
    /* background-color: rgb(132, 0, 255); */
    padding: 20px 1%;
    text-align: left;
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
}

.info p {
    margin: 0;
}

.page2leveldiv {
    background-color: #fff;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.page2leveldivinner {
    background: rgb(122, 196, 200);
    background: linear-gradient(90deg, rgba(122, 196, 200, 1) 0%, rgba(155, 198, 189, 1) 15%, rgba(213, 208, 132, 1) 29%, rgba(238, 149, 114, 1) 56%);
    width: 80%;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: smaller;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #6E4E9F;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangle2 {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #6E4E9F;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(180deg);
}

.raisedimmuneload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    top: 55px;
    left: -40%;
    transform: translate(63.5px, 0px);
}

.highimmuneload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    top: 55px;
    left: 40%;
    transform: translate(-63.5px, 0px);
}

.immuneload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    top: -55px;
    left: 0%;
}


.plus-sign {
    width: 20px;
    height: 40px;
    display: flex;
    background-color: #6E4E9F;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 5px;
    z-index: 1;
    position: relative;
}

.plus-signbefore {
    content: "";

    position: absolute;
    width: 55px;
    height: 20px;
    left: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Adjust the position as needed */
    top: 25px;
    background-color: #6E4E9F;
    /* Change the color as needed */
    border-radius: 5px;
    z-index: 2;
    color: #fff;
}

.indicator {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    color: #fff;
}



.indicator div {
    width: fit-content;
    background-color: #6E4E9F;
    padding: 10px 60px;
    border-radius: 30px;

}


.indicatorp {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 10px;
    color: #fff;
}

.indicatorp div {
    width: fit-content;
    background-color: #6E4E9F;
    padding: 10px 40px;
    border-radius: 30px;

}

.calcuresult {
    width: 100%;
    height: fit-content;
    /* background-color: #6E4E9F; */
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    /* justify-content: space-around; */
}


.calcuresultp {
    width: 100%;
    height: fit-content;
    /* background-color: #6E4E9F; */
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 24.578px;
    font-size: 1em;
    /* justify-content: space-around; */
}

.boxx {
    width: 200px;
    /* background-color: red; */
    height: 40px;
    display: flex;
    justify-self: baseline;
    font-size: 1em;
}

.boxxp {
    width: 160px;
    /* background-color: red; */
    height: 40px;
    display: flex;
    justify-self: baseline;
    font-size: 0.85em;
    font-size: 1em;
}

.number {
    height: 100%;
    background-color: #c9beda;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 1.5em;
}

.name {
    height: 100%;
    background-color: #d0cece;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: fit-content;
    min-width: 65%;
    padding: 0px 5px;

}

.marginforpp {
    width: 100%;
    height: 170px;
    /* background-color: #6E4E9F; */
}

.marginforp {
    display: none;
}

.downloadbuttonviewreport {
    position: absolute;
    top: 673px;
    background-color: #6E4E9F;
    color: #fff;
    left: 50%;
    padding: 21px 197px;
    border: 1px solid #6799C6;
    transform: translate(-50%);
    border-radius: 10px;

}

.downloadbuttonviewreport:hover {
    cursor: pointer;
}







@media screen and (max-width:1060px) {


    .logoimg {
        width: 80%;
    }


    .calcuresult {
        width: 100%;
        height: fit-content;
        /* background-color: #6E4E9F; */
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 35px;
     justify-content: center;
    }


    .downloadbuttonviewreport {
        position: absolute;
        top: 617px;
        background-color: #6E4E9F;
        color: #fff;
        left: 50%;
        padding: 18px 141px;
        border: 1px solid #6799C6;
        transform: translate(-50%);
    
    }



}